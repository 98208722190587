import { RadioGroup, Stack } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SensorDisplayValueMap } from '../../../index';
import redux, { RootState } from '../../../redux';
import RadioButton from './radio-button';

const allValue = 'all';

type DisplayValuesRadioGroupProps = {
  dataType: string;
  displayValues: SensorDisplayValueMap;
  fieldName: string;
  handleClose: () => void;
};

function DisplayValuesRadioGroup({
  dataType,
  displayValues,
  fieldName,
  handleClose,
}: DisplayValuesRadioGroupProps) {
  const dispatch = useDispatch();
  const dataFilterValue = useSelector(
    (state: RootState) => state.form.dataFilterValue,
  );
  const value = dataFilterValue ? dataFilterValue[fieldName] : undefined;
  const windowSize = useSelector((state: RootState) => state.form.windowSize);
  const windowFunction = useSelector(
    (state: RootState) => state.form.windowFunction,
  );

  return (
    <RadioGroup
      aria-labelledby="data-filter-value-radio-group"
      name="data-filter-value-radio-group"
      onChange={(e) => {
        let filter: Record<string, any> = {};
        if (e.target.value === allValue) {
          filter[fieldName] = undefined;
        } else {
          switch (dataType) {
            case 'boolean':
              filter[fieldName] = e.target.value === 'true';
              break;
            case 'float':
            case 'integer':
              filter[fieldName] = parseFloat(e.target.value);
              break;
            default:
              filter[fieldName] = e.target.value;
          }
        }
        dispatch(redux.form.setDataFilter(filter));
        const event = new CustomEvent('update-sample-rate', {
          detail: {
            dataFilterValue: filter,
            windowFunction,
            windowSize,
          },
        });
        window.dispatchEvent(event);
        handleClose();
      }}
      value={value === undefined ? allValue : value}
    >
      <Stack direction="row">
        <Stack direction="column" flexGrow={1} gap="14px" pl="10px">
          <RadioButton label="display-values-no-alarm-alarm" value={allValue} />
          {Object.keys(displayValues).map((key) => (
            <RadioButton
              key={key}
              label={`display-values-${displayValues[key].toLowerCase().replaceAll(' ', '-')}`}
              value={key.toString()}
            />
          ))}
        </Stack>
      </Stack>
    </RadioGroup>
  );
}

export default DisplayValuesRadioGroup;
