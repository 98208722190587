import { createSlice } from '@reduxjs/toolkit';

type FormState = {
  dataFilterValue?: Record<string, any>;
  managedMachineUuid: string;
  windowFunction: string;
  windowSize: string;
};

const initialState: FormState = {
  dataFilterValue: undefined,
  managedMachineUuid: '',
  windowFunction: 'first',
  windowSize: '1s',
};

const slice = createSlice({
  initialState,
  name: 'form',
  reducers: {
    setDataFilter: (state, action) => {
      state.dataFilterValue = action.payload;
    },
    setManagedMachineUuid: (state, action) => {
      state.managedMachineUuid = action.payload;
    },
    setWindowFunction: (state, action) => {
      state.windowFunction = action.payload;
    },
    setWindowSize: (state, action) => {
      state.windowSize = action.payload;
    },
  },
});

const form = {
  reducer: slice.reducer,
  setDataFilter: slice.actions.setDataFilter,
  setManagedMachineUuid: slice.actions.setManagedMachineUuid,
  setWindowFunction: slice.actions.setWindowFunction,
  setWindowSize: slice.actions.setWindowSize,
};

export default form;
