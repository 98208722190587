import { Popover } from '@mui/material';
import * as React from 'react';

import { SensorDisplayValueMap } from '../../../index';
import PopoverContentFilterSelector from './popover-content-filter-selector';

type SelectionPopoverProps = {
  anchorEl: HTMLDivElement;
  dataType: string;
  displayValues: SensorDisplayValueMap;
  fieldName: string;
  setAnchorEl: (anchorEl: HTMLDivElement | null) => void;
};

const sx = () => ({
  border: '1px solid #E8E8E8',
  borderRadius: '8px',
  boxShadow: '0px 2px 6px 0px #1A19191A',
});

function DataFilterSelectionPopover({
  anchorEl,
  dataType,
  displayValues,
  fieldName,
  setAnchorEl,
}: Readonly<SelectionPopoverProps>) {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-display' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 47,
      }}
      id={id}
      onClose={handleClose}
      open={open}
      slotProps={{
        paper: {
          sx,
        },
      }}
    >
      <PopoverContentFilterSelector
        dataType={dataType}
        displayValues={displayValues}
        fieldName={fieldName}
        handleClose={handleClose}
      />
    </Popover>
  );
}

export default DataFilterSelectionPopover;
