import React from 'react';
import { render, screen } from '@testing-library/react';
import '@testing-library/jest-dom';
import AlertTimeframeSelector from './AlertTimeframeSelector';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// Mock I18n.t function
const I18n = {
  t: (key) => key,
};
global.I18n = I18n;

describe('AlertTimeframeSelector', () => {
  it('displays time in creator timezone', () => {
    const creatorTimeZone = 'Europe/London';

    const props = {
      scheduledTimeStart: '09:00:00',
      scheduledTimeEnd: '17:00:00',
      scheduledDay: 'weekdays',
      scheduledOccurrence: 'between',
      creatorTimeZone: creatorTimeZone,
    };

    render(<AlertTimeframeSelector {...props} />);

    // Check if the start time is displayed in London time (GMT+1)
    expect(screen.getByDisplayValue('09:00 AM')).toBeInTheDocument();
    expect(screen.getAllByText('GMT')[0]).toBeInTheDocument();

    // Check if the end time is displayed in London time (GMT+1)
    expect(screen.getByDisplayValue('05:00 PM')).toBeInTheDocument();
    expect(screen.getAllByText('GMT')[1]).toBeInTheDocument();
  });

  it('handles "anytime" occurrence correctly', () => {
    const props = {
      scheduledTimeStart: null,
      scheduledTimeEnd: null,
      scheduledDay: null,
      scheduledOccurrence: 'anytime',
      creatorTimeZone: 'UTC',
    };

    render(<AlertTimeframeSelector {...props} />);

    expect(
      screen.getByText('alert_rules.form.scheduled.occurrence_options.anytime'),
    ).toBeInTheDocument();
  });

  it('updates hidden fields when times change', () => {
    const props = {
      scheduledTimeStart: '09:00:00',
      scheduledTimeEnd: '17:00:00',
      scheduledDay: 'weekdays',
      scheduledOccurrence: 'between',
      creatorTimeZone: 'UTC',
    };

    render(<AlertTimeframeSelector {...props} />);

    // Check for the presence of hidden fields
    expect(screen.getByDisplayValue('09:00:00')).toBeInTheDocument();
    expect(screen.getByDisplayValue('17:00:00')).toBeInTheDocument();
  });
});
