import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en.json';

const init = (cb: (error: boolean) => void) => {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init(
      {
        resources: {
          en: {
            translation: en,
          },
        },
        supportedLngs: ['en'],
      },
      (error) => {
        cb(!!error);
      },
    );
};

const translations = {
  init,
};

export default translations;
