import { Box } from '@mui/material';
import * as React from 'react';

import { SensorDisplayValueMap, SensorDisplayValueType } from '../../../index';
import DisplayValuesRadioGroup from './data-filter-radio-group';

type PopoverContentProps = {
  dataType: string;
  displayValues: SensorDisplayValueMap;
  fieldName: string;
  handleClose: () => void;
};

const contentSx = () => ({
  padding: '21px 20px',
  width: 454,
});

function PopoverContentFilterSelector({
  dataType,
  displayValues,
  fieldName,
  handleClose,
}: Readonly<PopoverContentProps>) {
  return (
    <Box sx={contentSx}>
      <DisplayValuesRadioGroup
        dataType={dataType}
        displayValues={displayValues}
        fieldName={fieldName}
        handleClose={handleClose}
      />
    </Box>
  );
}

export default PopoverContentFilterSelector;
