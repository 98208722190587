import { Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import MuiXLicense from '../licensing/MuiXLicense';
import redux from './redux';
import translations from './translations';
import DataFilterDropdown from './views/data-filter-dropdown';
import SampleRateDropdown from './views/sample-rate-dropdown';
import SyncManager from './views/sync-manager';

export type SensorDisplayValueType = {
  dry_contact_closed: {
    datatype: string;
    display_values: SensorDisplayValueMap;
  };
};

export type SensorDisplayValueMap = Record<string, any>;

type ExportCSVContainerProps = {
  machineModelShortName: string;
  samplesManagedMachinePath: string;
  sensorDisplayValue: SensorDisplayValueType;
};

const SampleRateSelector = (props: ExportCSVContainerProps) => {
  const [translationLoaded, setTranslationLoaded] = useState(false);

  useEffect(() => {
    if (!translationLoaded) {
      translations.init((error) => {
        setTranslationLoaded(!error);
      });
    }
  }, []);
  if (!translationLoaded) {
    return null;
  }
  return (
    <>
      <Provider store={redux.store}>
        <LocalizationProvider adapterLocale="en" dateAdapter={AdapterDayjs}>
          <>
            <Stack direction="row" justifyContent="space-between">
              <SampleRateDropdown />
              {props.machineModelShortName === 'B' &&
                props.sensorDisplayValue.dry_contact_closed !== undefined && (
                  <DataFilterDropdown
                    dataType={
                      props.sensorDisplayValue.dry_contact_closed.datatype
                    }
                    displayValues={
                      props.sensorDisplayValue['dry_contact_closed']
                        .display_values
                    }
                    fieldName="dry_contact_closed"
                  />
                )}
            </Stack>
            <SyncManager {...props} />
          </>
        </LocalizationProvider>
      </Provider>
      <MuiXLicense />
    </>
  );
};

export default SampleRateSelector;
