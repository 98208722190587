import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SensorDisplayValueMap } from '../../index';
import { RootState } from '../../redux';
import DataFilterSelectionPopover from './selection-popover';

const containerSx = () => ({
  alignItems: 'center',
  border: '1px solid #E8E8E8',
  borderRadius: 30,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  height: 37,
  justifyContent: 'space-between',
  paddingLeft: '20px',
  paddingRight: '20px',
  width: 350,
});

const titleSx = () => ({
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 19.12,
});

const selectionStyle = {
  color: '#1C5BFE',
};

const chevronSx = () => ({
  color: '#1A191980',
  fontSize: 10,
});

type DataFilterDropdownProps = {
  dataType: string;
  displayValues: SensorDisplayValueMap;
  fieldName: string;
};

function DataFilterDropdown(props: DataFilterDropdownProps) {
  const { dataType, displayValues, fieldName } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const dataFilterValue = useSelector(
    (state: RootState) => state.form.dataFilterValue,
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const retrieveSelectedValueLabel = () => {
    let label = 'display-values-no-alarm-alarm';
    Object.keys(displayValues).forEach((key) => {
      if (
        dataFilterValue !== undefined && Object.hasOwn(dataFilterValue , fieldName) && dataFilterValue[fieldName] !== undefined &&
        dataFilterValue[fieldName].toString() === key.toString()
      ) {
        label = `display-values-${displayValues[key].toLowerCase().replaceAll(' ', '-')}`;
      }
    });

    return label;
  };

  return (
    <>
      <Box
        className="data-filter-dropdown"
        onClick={handleClick}
        sx={containerSx}
      >
        <Typography sx={titleSx}>
          {t('display-values-title')}
          <span style={selectionStyle}>{t(retrieveSelectedValueLabel())}</span>
        </Typography>
        <Box sx={chevronSx}>
          <i className="fa fa-chevron-down"></i>
        </Box>
      </Box>
      <DataFilterSelectionPopover
        anchorEl={anchorEl}
        dataType={dataType}
        displayValues={displayValues}
        fieldName={fieldName}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
}

export default DataFilterDropdown;
